import { HTTP } from '../../axios/axios'
let data

async function getAll (lang) {
  try {
    const res = await HTTP.get('/legacy', { headers: { 'accept-language': lang } })
    data = res.data[0]
    return data.zigzags
  } catch (error) {
    console.log(error)
  }
}
async function addOne (zigzag, lang) {
  data.zigzags = [...data.zigzags, zigzag]
  return await HTTP.patch('/legacy/' + data._id, data)
}
async function update (zigzag, id) {
  return await HTTP.patch('/legacy/' + data._id, data)
}
async function deleteOne (zigzag) {
  data.zigzags = data.zigzags.filter(x => x._id !== zigzag._id)
  return await HTTP.patch('/legacy/' + data._id, data)
}
async function deleteMany (arr) {
  data.zigzags = data.zigzags.filter(x => arr.indexOf(x._id) === -1)
  return await HTTP.patch('/legacy/' + data._id, data)
}

async function changeOrder (zigzags) {
  return await HTTP.patch('/legacy/' + data._id, { zigzags, lang: data.lang })
}

export default { getAll, deleteMany, addOne, update, deleteOne, changeOrder }
